.brand-tab-content {
  border: 1px solid transparent !important;
  border-color: transparent #c4c9d0 #c4c9d0 !important;
  min-height: 650px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: white !important;
  border-bottom-color: transparent !important;
}

.brand-tooltip {
  background-color: #c4c9d0;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}