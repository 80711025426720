.stepper {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid transparent;
  margin-right: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: all .5s;
  border-color: #828282;
  color: #828282;
}

/* .stepper.current {
  border-color: #07074d;
  color: #07074d;
} */

.stepper.current {
  background-color: rgba(4, 106, 243, 0.1);
  color: #07074d;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}