.avatar {
  width: 133px;
  height: 133px;
  background-color: #07074d;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
}