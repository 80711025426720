.tab {
  &_info {
    margin-bottom: 2.5rem;
  }

  &_tooltip {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 10px;
    }
  }
}

.field {
  &_info {
    word-break: break-word;
  }

  &_wrapper {
    display: flex;
    padding: .5rem 0;
  }

  &_label {
    display: inline-flex;
    letter-spacing: -0.01em;
    width: 120px;
    flex-shrink: 0;
    color: #8094ae;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.65;
    text-align: left;
  }

  &_value {
    text-transform: capitalize;
    text-align: right;
    font-weight: 500;
    width: 100%;
    color: #526484;
    font-size: .875rem;
    line-height: 1.65;

    &_email {
      text-align: right;
      font-weight: 500;
      width: 100%;
      color: #526484;
      font-size: .875rem;
      line-height: 1.65;
    }

    &_link {
      cursor: pointer;
      text-align: right;
      font-weight: 500;
      width: 100%;
      color: #526484;
      font-size: .875rem;
      line-height: 1.65;
      text-decoration: none;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.additional {
  &_info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dbdfea;

    &_heading {
      font-size: 11px;
      line-height: 1.2;
      letter-spacing: 0.2em;
      color: #8094ae;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }
  }
}

.section {
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &_link {
    cursor: pointer;
    color: #07074d !important;
    font-weight: 700;
  }

  &_document {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    &_link {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  &_note {

    &_text {
      padding: 1rem 1.25rem;
      background: #f5f6fa;
      border-radius: 4px;
      margin-bottom: 10px;
    }

    &_heading {
      margin-bottom: 20px;
    }


    &_meta {
      font-size: 12px;
      color: #8094ae;
      margin-top: .75rem;
      font-weight: 400;
    }
    
  }

  &_sep {
    height: .25rem;
    display: block;
    visibility: hidden;
    padding: 0 0.25rem;
  }

  &_date,
  &_time,
  &_author {
    color: #526484;
  }

  &_author {
    display: inline-block;
  }

  &_delete {
    display: inline-block;
    margin-left: 0.75rem;
    color: #e85347 !important;
    cursor: pointer;
    font-weight: 500;
  }
}
  


@media (min-width: 768px) {

  .field {
    &_info {
      max-width: 960px;
      display: flex;
      flex-wrap: wrap;
      margin: -0.25rem -3.25rem;
    }

    &_item {
      width: 50%;
      padding: 0 3.25rem;
    }

    &_long_item {
      width: 100%;
      padding: 0 3.25rem;
    }

    &_wrapper {
      padding: .75rem 0;
    }
  }
}

@media (min-width: 576px) {
  .section {
    &_note {
      &_text {
        padding: 1.25rem 1.5rem;
      }
    }

    &_sep {
      height: auto;
      display: inline-block;
      visibility: visible;
    }
  }
}